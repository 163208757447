<!--
 * @Author: niweixing
 * @Date: 2023-10-25 16:22:58
 * @LastEditors: niweixing
 * @LastEditTime: 2023-11-20 09:28:00
 * @Description: 
-->
<template>
  <router-view></router-view>
</template>
<style scoped>
* {
  /* height: 1624px; */
  opacity: 1;

  /* 背景色 */
  /* background: linear-gradient(180deg, #d9edfb 0%, #ffffff 100%); */
  /* background:  #F4F6F8; */
  background: #F6F6F6
}
</style>
 
